import { Component } from 'react'

class NavEvent extends Component {
  ghostMenu = ['/']

  bannerHeight = 480

  baseUrl = process.env.RAZZLE_ORIGIN

  menus = [
    {
      key: 'home',
      pathname: '/',
      name: '首页',
    },
    {
      key: 'renter',
      name: '租车服务',
      pathes: ['/duanzu', '/changzu', '/haoche', '/companyRent'],
      children: [
        {
          key: 'duanzu',
          pathname: '/duanzu',
          name: '短租',
        },
        {
          key: 'changzu',
          pathname: '/changzu',
          name: '长租',
        },
        {
          key: 'haoche',
          pathname: '/haoche',
          name: '个性车',
        },
        {
          key: 'companyRent',
          pathname: '/companyRent',
          name: '企业租车',
        },
      ],
    },
    {
      key: 'owner',
      name: '车主招募',
      pathes: ['/carOwner', '/business'],
      children: [
        {
          key: 'carOwner',
          pathname: '/carOwner',
          name: '成为车主',
        },
        {
          key: 'business',
          // pathname: '/business',
          pathname: 'https://atzuche00.mikecrm.com/KoLRvUj',
          name: '商家入驻',
        },
      ],
    },
    {
      key: 'about',
      pathname: '/about',
      name: '关于凹凸',
      nofollow: true,
    },
    {
      key: 'register',
      pathname:
        '/signup?utm_source=SEO&utm_medium=SEO&utm_campaign=SEO&utm_term=aa84ec947f0a72b161a8d27598eda21e',
      name: '注册',
      nofollow: true,
      extra: {
        text: '2075大礼包',
        bgUrl: 'https://cdn.atzuche.com/pc/bubble-2075.png?v=2',
      },
    },
  ]

  constructor(props) {
    super(props)

    this.state = {
      needGhost: this.props.www && this.ghostMenu.includes(this.props.location.pathname),
      ghost: this.props.www && this.ghostMenu.includes(this.props.location.pathname),
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onBodyScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onBodyScroll)
  }

  onBodyScroll = e => {
    if (!this.state.needGhost) {
      return
    }

    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop

    if (scrollTop >= this.bannerHeight) {
      this.setState({
        ghost: false,
      })
    } else {
      this.setState({
        ghost: true,
      })
    }
  }
}

export default NavEvent
