import React from 'react'
import Event from './event'
import classnames from 'classnames'
import './style.scss'
import TOOL from 'src/hoc/tool'
import AtButton from '../../ui/button'

class Registered extends Event {
  render() {
    const {
      phoneNum,
      imgNum,
      smsNum,
      isShowImgInput,
      isShowRetry,
      retrySecond,
      imgUrl,
    } = this.state

    const { className } = this.props

    const mainCN = classnames('registered', className)

    return (
      <div className={mainCN}>
        <h3 className="registered_title">
          注册即享
          <span>2075元</span>
          租车大礼包
        </h3>
        <div className="registered_box">
          <input
            value={phoneNum}
            onChange={this.inputOnChange.bind(this, 'phoneNum')}
            placeholder="请输入手机号码"
            type="text"
          />
        </div>
        {!!isShowImgInput && (
          <div className="registered_box">
            <input
              value={imgNum}
              onChange={this.inputOnChange.bind(this, 'imgNum')}
              placeholder="请输入图形验证码"
              maxLength="4"
              type="text"
            />
            <img src={imgUrl} alt="" onClick={this.getImgUrl.bind(null, null)} />
          </div>
        )}
        <div className="registered_box">
          <input
            value={smsNum}
            onChange={this.inputOnChange.bind(this, 'smsNum')}
            placeholder="请输入短信验证码"
            maxLength="6"
            type="text"
          />
          {!isShowRetry && <span onClick={this.getSmsCode}>获取验证码</span>}
          {isShowRetry && <span>重新获取 {retrySecond}s</span>}
        </div>

        <p className={`agree_license ${this.state.agreedLicense ? ' agreed' : ''}`}>
          <a className="agree" onClick={this.changeAgreedLicense}>
            我同意
          </a>
          <a href="/about/vipServiceWeb" target="_blank">
            《凹凸租车会员服务协议》
          </a>
          和
          <a href="/about/privacyPolicy" target="_blank">
            《凹凸租车隐私协议》
          </a>
        </p>

        <AtButton size="middle" className="registered_button" onClick={this.onRegisterHandler}>
          注册领取
        </AtButton>
        <div className="registered_tips">
          让您的闲置车辆开始挣钱，<a href="/sh/carOwner">成为凹凸车主&gt;</a>
        </div>
      </div>
    )
  }
}

export default TOOL(Registered)
